import React from 'react'
import Layout from 'components/layout'
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import styles from './index.module.scss'
import intl from '@/intl'

const ConditionalLayout = ({ children, ...rest }) => {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        return (
          modal ? (
            <React.Fragment>
              <Link className={`fixed right-0 top-0 uppercase z-50 p-4 focus:outline-none text-base text-gris ${styles.closeBtn}`} to={closeTo}>{intl(`close`)}</Link>
              {children}
            </React.Fragment>
          ) : (
            <Layout { ...rest }>
              {children}
            </Layout>
          )
        )
      }}
    </ModalRoutingContext.Consumer>
  )
}

export default ConditionalLayout

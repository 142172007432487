import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'
import Img from 'gatsby-image'
import styles from './index.module.scss'
import { useRecoilState } from 'recoil'
import { navigate } from 'gatsby'
import { productSlideshowIndexState } from 'state'

const getTranslate = () => {
  if (window !== 'undefined') return window.innerWidth
  return 800
}

const variants = (page) => {
  return {
    enter: (direction) => {
      return {
        x: direction > 0 ? getTranslate() : -getTranslate(),
        opacity: 1,
        zIndex: 2
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      let adoptedDir = direction < 0 ? getTranslate() : -getTranslate()
      if (direction === 0 && page > 1) adoptedDir = getTranslate()
      return {
        zIndex: 0,
        x: adoptedDir,
        opacity: 1
      }
    }
  }
}

const Slideshow = ({ slides, description, lang }) => {
  const [[page, direction], setPage] = useRecoilState(productSlideshowIndexState)
  const imageIndex = wrap(0, slides.length, page)
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }
  const slide = slides[imageIndex]
  const slideLen = slide ? slide.length : 0
  const isClosingSlide = (imageIndex + 1) === slides.length
  const Image = ({ imgStyle, fluid }) => (
    <Img
      loading={`eager`}
      imgStyle={{objectFit: imgStyle}}
      className={styles.img}
      fluid={fluid}
    />
  )
  const getAppearance = (appearance) => {
    if (appearance === `5/12`) return `3/7`
    if (appearance === `7/12`) return `4/7`
    return `4/7`
  }
  const child = () => {
    return (
      <>
        { (slideLen === 1) &&
          <>
            {/* Second Slide with product information */}
            { (imageIndex === 1 && description) &&
              <div className={`flex items-stretch h-full`}>
                <div className={`w-4/7`}>
                  <Image imgStyle={'cover'} fluid={slide[0].fluid} />
                </div>
                <div className={`w-3/7 h-full relative`}>{description}</div>
              </div>
            }
            {/* The rest of the slides */}
            { (imageIndex !== 1 || !description) &&
              <Image imgStyle={'cover'} fluid={slide[0].fluid} />
            }
          </>
        }
        { (slideLen > 1) &&
          <>
            {/* Diptyque slides */}
            <div className={`flex items-stretch h-full`}>
              <div className={`w-${getAppearance(slide[0].appearance)}`}>
                <Image imgStyle={'cover'} fluid={slide[0].fluid} />
              </div>
              <div className={`w-${getAppearance(slide[1].appearance)}`}>
                <Image imgStyle={'cover'} fluid={slide[1].fluid} />
              </div>
            </div>
          </>
        }
      </>
    )
  }
  const onPrevious = () => {
    if (imageIndex === 0) paginate(1)
    if (imageIndex > 0) paginate(-1)
  }
  const vars = variants(page)
  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className={`w-full h-full absolute`}
          key={page}
          custom={direction}
          variants={vars}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{
            x: { type: 'spring', stiffness: 100, damping: 400 },
            opacity: { duration: 0.4 }
          }}
        >{child()}</motion.div>
      </AnimatePresence>
      <div className={`w-full h-full absolute z-10 ${imageIndex === 1 ? `pointer-events-none` : `pointer-events-auto`}`}>
        <button className={`${styles.next} w-1/2 h-full pointer-events-auto ${imageIndex > 0 ? `cursor-left` : `cursor-right`}`} aria-label={`Next Button`} onClick={onPrevious} />
        <button className={`${styles.prev} w-1/2 pointer-events-auto ${imageIndex === 1 ? `h-9/12 top-0 absolute`:`h-full`} ${isClosingSlide ? `cursor-back` : `cursor-right`}`} aria-label={`Previous Button`} onClick={() => { isClosingSlide ? navigate(`/${lang}`) : paginate(1)} } />
      </div>
    </>
  )
}

export default Slideshow

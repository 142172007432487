import React from 'react'
import styles from './index.module.scss'

const IconBtnLink = ({ to, className, children, icon }) => (
  <a target={`_blank`} href={to} className={`${className} ${styles.catLink} relative`}>
    <div className={`${styles.icon} absolute top-0 left-0`}>{icon}</div>
    <div className={`${styles.title} top-0 left-0 whitespace-no-wrap`}>{children}</div>
  </a>
)

export default IconBtnLink

import React, { useState } from 'react'
import styles from './index.module.scss'

const Dimension = ({ className, width, height, length, altWidth, altHeight, altLength, showDoubleSign = false }) => {
  const [isSwitch, setIsSwitch] = useState(true)
  const dimensionSeparator = <span className={`relative -top-1`}>×</span>
  const onClick = () => {
    setIsSwitch(!isSwitch)
  }
  const Btn = ({ children, className }) => (
    <button className={`${styles.catLink} ${className} uppercase relative select-none text-left`}>
      <div className={`${styles.dot} bg-black inline-block`}></div>
      <div className={`${styles.title} inline-block relative whitespace-no-wrap w-4`}>{children}</div>
    </button>
  )
  const w = isSwitch ? width : altWidth
  const h = isSwitch ? height : altHeight
  const l = isSwitch ? length : altLength
  return (
    <button className={`${className} cursor-pointer select-none`} onClick={onClick}>
      <div className={`inline-block`}>
        <Btn className={`pr-8 ${isSwitch ? styles.highlight : ``}`}>cm</Btn>
        <Btn className={`pr-12 ${!isSwitch ? styles.highlight : ``}`}>in</Btn>
      </div>
      <div className={`${styles.info} inline-block`}>
        <span>{w} {dimensionSeparator} {h} {dimensionSeparator} {l} {showDoubleSign ? `x2` : ``}</span><br/>
      </div>
    </button>
  )
}

export default Dimension

import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import ConditionalLayout from 'components/conditional-layout'
import { graphql } from 'gatsby'
import SEO from 'components/seo'
import Slideshow from 'components/slideshow'
import MobileBtn from 'components/mobile-btn'
import IconBtn from 'components/icon-btn-link'
import intl from '@/intl'
import Img from 'gatsby-image'
import Dimension from 'components/dimension'
import { useRecoilState } from 'recoil'
import { productSlideshowIndexState} from 'state'
import { getSources } from '@/utils'
import LoopScroll from 'components/loop-scroll'
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size'

const ProductPage = (props) => {
  const parent = React.createRef()
  const [[page], setPage] = useRecoilState(productSlideshowIndexState)
  const [isModal, setIsModal] = useState()
  const windowW = useWindowWidth()
  const windowH = useWindowHeight()
  const { title, categories } = props.pageContext.product
  const information = props.pageContext.product && props.pageContext.product.information ? props.pageContext.product.information.information : undefined
  const { email } = props.data.allContentfulMisc.nodes[0]
  const { otherSlides: medias, firstslide, secondslide } = props.data.contentfulProduct
  const euMetricProduct = props.data.allContentfulProduct.nodes.filter(item => item.node_locale === `fr`)[0]
  const ukMetricProduct = props.data.allContentfulProduct.nodes.filter(item => item.node_locale === `en`)[0]
  const width = euMetricProduct.tempWidth
  const height = euMetricProduct.tempHeight
  const length = euMetricProduct.tempLength
  const altWidth = ukMetricProduct.tempWidth
  const altHeight = ukMetricProduct.tempHeight
  const altLength = ukMetricProduct.tempLength
  const collection = props.data.contentfulProduct.collection ? props.data.contentfulProduct.collection : undefined
  const pad = `p-4`
  let ignoreNext = false
  let diptyqueCounter = 0
  let filteredMedia = []
  let allMedias = []
  let allMediaMobile = []
  if (medias) {
    medias.forEach((item, i) => {
      if (ignoreNext) {
        ignoreNext = false
        return
      }
      const appearanceMedia1 = medias[i].appearance ? medias[i].appearance : 'Fullscreen'
      const appearanceMedia2 = (medias[i+1] && medias[i+1].appearance) ? medias[i+1].appearance : 'Fullscreen'
      if (medias[i].appearance && medias[i+1] && medias[i+1].appearance && (medias[i].appearance === '5/12' || medias[i].appearance === '7/12') && (medias[i+1].appearance === '5/12' || medias[i+1].appearance === '7/12')) {
        filteredMedia.push([
          { appearance: appearanceMedia1, counter: diptyqueCounter, fluid: getSources(medias[i]) },
          { appearance: appearanceMedia2, counter: diptyqueCounter, fluid: getSources(medias[i + 1]) }
        ])
        diptyqueCounter += 1
        ignoreNext = true
      } else {
        filteredMedia.push([
          { appearance: appearanceMedia1, fluid: getSources(medias[i]) }
        ])
        ignoreNext = false
      }
    })
    allMedias = [
      [{ appearance: 'Fullscreen', fluid: getSources(firstslide) }],
      [{ appearance: '7/12', fluid: getSources(secondslide) }],
      ...filteredMedia
    ]
    allMediaMobile = [
      [{ appearance: 'Fullscreen', fluid: getSources(secondslide) }],
      ...medias.map((item) => {
        return [{ appearance: `Fullscreen`, fluid: getSources(item) }]
      })
    ]
  }
  const showDoubleSign = props.path.includes('trio-multilaque')
  const dimensionText = (
    <Dimension
      width={width}
      height={height}
      length={length}
      altWidth={altWidth}
      altHeight={altHeight}
      altLength={altLength}
      showDoubleSign={showDoubleSign}
    />
  )
  const otherDimensions = euMetricProduct?.extraDimensions?.map((item, index) => {
    const euExtraDimensions = item
    const ukExtraDimensions = ukMetricProduct.extraDimensions[index]
    return (
      <Dimension
        key={`dimension-${euExtraDimensions.width}-${euExtraDimensions.height}-${index}`}
        width={euExtraDimensions.width}
        height={euExtraDimensions.height}
        length={euExtraDimensions.length}
        altWidth={ukExtraDimensions.width}
        altHeight={ukExtraDimensions.height}
        altLength={ukExtraDimensions.length}
      />
    )
  })
  const description = (
    <section className={`bg-white w-full h-full absolute top-0 right-0 z-10`}>
      <div className={`flex h-11/12 items-end`}>
        <div className={`w-full`}>
          { information &&
          <div className={`flex flex-wrap w-full ${pad} p-4 pl-logo pb-2`}>
            <div className={`w-1/4 flex-col`}>{intl('design')}</div>
            <div className={`w-3/4 flex-col pl-8`}>{information}</div>
          </div>
          }
          { collection &&
          <div className={`flex flex-wrap ${pad} p-4 pl-logo pb-2`}>
            <div className={`w-1/4 flex-col`}>{intl('collection')}</div>
            <div className={`w-3/4 flex-col pl-8`}>{collection}</div>
          </div>
          }
          { (width && height && length) &&
          <div className={`flex flex-wrap ${pad} p-4 pl-logo pb-12`}>
            <div className={`w-1/4 flex-col`}>{intl('dimensions')}</div>
            <div className={`w-3/4 flex-col pl-8`}>{dimensionText}{otherDimensions}</div>
          </div>
          }
        </div>
      </div>
    </section>
  )
  const currentSlideIndex = page < 0 ? ((allMedias.length) - (Math.abs(page % allMedias.length) - 1)) : Math.abs((page) % allMedias.length) + 1
  useEffect(() => {
    const el = parent.current
    const hero = [...el.getElementsByClassName('hero-image')]
    const productMedias = [...el.getElementsByClassName('product-media')]
    if (hero) {
      hero.forEach(item => {
        item.style.width = `${windowW}px`
        item.style.height = `${windowH * 0.9}px`
      })
    }
    if (productMedias) {
      const mobileBlockH = windowW * 1.6
      productMedias.forEach(item => {
        item.style.width = `${windowW}px`
        item.style.height = `${mobileBlockH}px`
      })
    }
  })
  useEffect(() => {
    const modal = document.getElementsByClassName(`ReactModal__Content`)[0]
    setIsModal(modal !== undefined)
  }, [ setIsModal ])
  useEffect(() => {
    return () => {
      setTimeout(() => {
        setPage([0, 0])
      }, 800)
    }
  }, [setPage])
  const mailToBody = `${intl(`name`).toUpperCase()}:%0D%0A${intl(`city_inquiry`).toUpperCase()}:%0D%0A${intl(`country_inquiry`).toUpperCase()}:%0D%0A${intl(`object_inquiry`).toUpperCase()}: ${categories.title} · ${title}`
  const inquiryMailTo = `mailto:${email}?subject=${intl(`inquire`).toUpperCase()} – ${categories.title} · ${title}&body=${mailToBody}`
  const onInformation = () => {
    if (page === 0) setPage([1, 1])
    else setPage([1, 0])
  }
  const onTitleClick = () => {
    window.history.back()
  }
  const metaImage = () => {
    try {
      return {
        src: `https://${allMedias[0][0].fluid[1].src.split(`.jpg`)[0].replace('//', '')}.jpg?w=1000&h=562&q=70`,
        width: 1000,
        height: 526
      }
    } catch (error) {
      return undefined
    }
  }
  return (
    <ConditionalLayout>
      <SEO
        title={title}
        lang={props.pageContext.code}
        description={information}
        pathname={props.location.pathname}
        image={metaImage()}
      />
      <div ref={parent} className={`product-page relative z-20 bg-white text-base`}>
        <div className={`w-screen h-screen lg-overflow-hidden relative lg-justify-center lg-items-center uppercase hidden lg:flex`}>
          <Slideshow lang={props.pageContext.code} description={description} slides={allMedias} />
          <div className={`h-full`}>
            { !isModal && <section className={`w-3/7 absolute top-0 right-0 z-20 text-base ${pad} p-4 pl-logo`}>{categories.title} · {title}</section> }
            { isModal && <div className={`w-3/7 absolute top-0 right-0 z-20 text-base uppercase text-left ${pad} p-4 pl-logo`}><button className={`uppercase`} onClick={onTitleClick}>{categories.title}</button> · {title}</div> }
            <section className={`w-3/7 absolute bottom-0 right-0 z-20 ${pad} p-4 pl-logo`}>
              <div className={`flex flex-wrap`}>
                { page !== 1 && <button onClick={onInformation} className={`w-1/4 flex-col uppercase`}>{intl('information')}</button> }
                { page === 1 && <div className={`w-1/4 flex-col uppercase`}>{intl('information')}</div> }
                <div className={`w-3/4 flex-col pl-8`}><IconBtn icon={`→`} to={inquiryMailTo}>{intl('inquire')}</IconBtn></div>
              </div>
            </section>
            <section className={`absolute bottom-0 right-0 z-20 ${pad}`}>{currentSlideIndex} · {allMedias.length}</section>
          </div>
        </div>
        <div className={`block lg:hidden uppercase`}>
          <LoopScroll disableInDesktop fixedTargetIndex={1}>
            <h1 className={`fixed z-20 w-8/12 p-4 pl-logo ${styles.closeBtn}`}>{title}</h1>
            <div className={`w-full h-full overflow-hidden hero-image`}>
              { allMedias[0] && allMedias[0][0] && <Img className={`${styles.img}`} imgStyle={{objectFit: `cover`}} fluid={allMedias[0][0].fluid} />}
            </div>
            <div id={`logo-bottom`} className={`h-1 block`}></div>
            <section className={`w-full ${pad} p-4 pl-logo ${isModal ? `pt-4` : `pt-4`}`}>
              <p className={`mb-4`}>{intl('design')}</p>
              <p className={`mb-6`}>{information}</p>
              <div className={`w-full flex flex-row mb-6`}>
                <div className={`flex-1`}>{intl('dimensions')}</div>
                <div className={`flex-1`}>{dimensionText}<br/>{otherDimensions}</div>
              </div>
              <MobileBtn className={`mb-2`} to={inquiryMailTo}>{intl('inquire')}</MobileBtn>
            </section>
            {
              allMediaMobile.map((item, i) => {
                return (
                  <div className={`w-full h-full overflow-hidden product-media`} key={`mobile-media-${i}`}>
                    <Img className={`${styles.img}`} fluid={item[0].fluid} />
                  </div>
                )
              })
            }
            <div id={`title-bottom`} className={`block`}></div>
          </LoopScroll>
        </div>
      </div>
    </ConditionalLayout>
  )
}

export const ProductsPageQuery = graphql`
  query ProductsPageQuery($id: String, $code: String, $contentful_id: String) {
    allContentfulMisc(filter: {node_locale: {eq: $code}}) {
      nodes {
        email
      }
    }
    allContentfulProduct(filter: { contentful_id: { eq: $contentful_id}}) {
      nodes {
        tempLength
        tempWidth
        tempHeight
        extraDimensions {
          width
          height
          length
        }
        node_locale
      }
    }
    contentfulProduct(id: {eq: $id}) {
      tempLength
      tempWidth
      tempHeight
      collection
      extraDimensions {
        width
        height
        length
      }
      otherSlides {
        appearance
        desktop {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobile {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      firstslide {
        desktop {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobile {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      secondslide {
        desktop {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobile {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default ProductPage
